import React, { useEffect, useState } from 'react'
import AppLayout from '../../layout/components/AppLayout'
import userServices from '../userServices';

import { AgGridReact } from 'ag-grid-react';
/*
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
*/

export default function UserLocationPage() {

  const [userLocationList,setUserLocationList] = useState(null);

  const getUserLocationList = () =>{
    userServices.getUserLocationList().then((res)=>{
      setUserLocationList(res.data);
    }).catch(error=>{
      console.log(error);
    })
  }

  const [columnDefs] = useState([
    {sortable: true,filter:true, field:"id", },
    {sortable: true,filter:true, field:"title", },
    {sortable: true,filter:true, field:"city", },
    {sortable: true,filter:true, field:"state", },
    {sortable: true,filter:true, field:"address", },
    {sortable: true,filter:true, field:"phone", },
    {sortable: true,filter:true, field:"email", },
    {sortable: true,filter:true, field:"gst", },

  ])

  useEffect(()=>{
    getUserLocationList();
  },[])

  return (
      <AppLayout>
        <div className="container">
            <div className="row mt-3">
              <div className="col-md-12">
                  <h5 className="float-start">User Location</h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                  {userLocationList && 
                    <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                      <AgGridReact
                          rowData={userLocationList}
                          columnDefs={columnDefs}>
                      </AgGridReact>
                    </div>
                  }
              </div>
            </div>
        </div>
      </AppLayout>
    
  )
}
