import React from 'react'
import AppLayout from '../../layout/components/AppLayout'

export default function ProductListPage() {
  return (
    <AppLayout>
        <div className="container">
            <div>Product List </div>
        </div>
    </AppLayout>
  )
}
