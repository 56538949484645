import React, { useEffect, useState, useCallback } from 'react'

import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';



import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';

import MasterServices from '../../services/masterServices';
import customerServices from '../customerServices';

import JobForm from '../../job/components/JobForm';
import CustomerReceipt from './CustomerReceipt';
import JobStatus from '../../job/components/JobStatus';
import moment from 'moment';


const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/job/details/${props.data.id}`}>{props.data.id }</Link> }
        </>
    )
}


const srNoData = (props) =>{
    return (
        <>
            {props.data.newSrNo == ""?
                <span>{props.data.sno}</span>
                :
                <>
                    <span className="p-1 text-white bg-danger text-decoration-line-through">{props.data.sno}</span>
                    <span className="p-1 text-white bg-success ms-2">{props.data.newSrNo}</span>
                </>
            }
        </>
    )
}




let grid;

export default function CustomerJobGrid({customerId, customerName}) {

    const [customerJobList,setcustomerJobList] = useState(null);
    const [masters,setMasters] = useState(null);
    const getCustomerJobList = useCallback(()=>{
        customerServices.getCustomerJobs(customerId).then(res=>{
            grid.api.setRowData(res.data);
            setcustomerJobList(res.data);
        })
    },[customerId]);

    const getMasterList = () => {
        MasterServices.get(["receving-mode","job-type","job-status","warranty-status","brand-list","product-list","problem-list","physically-check-list","box-condition-list","accessory-present-list","user-location"]).then(res=>{
            setMasters(res);
        });
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }


    useEffect(()=>{
        getMasterList();
    },[]);



    /* Grid Work */

    const updateData =(params) =>{
        //console.log(params);
    }

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        editable: true,
        width:80,
        wrapText: true,     // <-- HERE
        autoHeight: true,   // <-- & HERE    
        cellStyle: {fontSize: '11px', fontWeight:"bold", lineHeight:1, borderLeft:"#000 1px solid", borderTop:"#000 1px solid", padding:"1px",}
    })


    const rowClassRules = {
        'bg-success': params => params.data.jobStatus === 1,
        'bg-info': params => params.data.jobStatus === 3,
        'bg-warning' : params => params.data.jobStatus === 4,
        
        
    };

    const colDefs = [
        { field:"id",sortable: true, colId:"job.id",  filter:true, cellRenderer: CellLink, headerName: '#', width:50},
        { field:"recevingMode",sortable: true, filter:true,  colId:"job.recevingMode", headerName:"Mode", valueGetter: params => getMasterLabel("receving-mode",params.data.recevingMode), width:80 },
        { field:"jobType",sortable: true, headerName:"Type", filter:true,  colId:"job.jobType", valueGetter: params => getMasterLabel("job-type",params.data.jobType), width:70 },
        { field:"model",sortable: true, filter:true,  colId:"product.model" },

        { headerName:"R. Date", field:"recevingDate",sortable: true, filter:true,  colId:"job.recevingDate", width:80, valueGetter: params => params.data.recevingDate!==null? moment(params.data.created_at).format("MM/DD/YYYY"):".." },
        { headerName:"S. Date", field:"serviceDate",sortable: true, filter:true,  colId:"job.serviceDate", width:80, valueGetter: params => params.data.serviceDate!==null?moment(params.data.serviceDate).format("MM/DD/YYYY"):".." },
        { headerName:"C. Date", field:"closeDate",sortable: true, filter:true,  colId:"job.closeDate", width:80, valueGetter: params => params.data.closeDate!==null?moment(params.data.closeDate).format("MM/DD/YYYY"):".." },
        { headerName:"H. Date", field:"handoverDate",sortable: true, filter:true,  colId:"job.handoverDate", width:80, valueGetter: params => params.data.handoverDate!==null?moment(params.data.handoverDate).format("MM/DD/YYYY"):".." },



        { field:"brand",sortable: true, filter:true,  colId:"brand_list.title", valueGetter: params => getMasterLabel("brand-list",params.data.brand), },
        { field:"product",sortable: true, filter:true,  colId:"product_list.title", valueGetter: params => getMasterLabel("product-list",params.data.product) },
        
        
        
        
        { field:"warrantyStatus",sortable: true,headerName:"WTY", filter:true,  colId:"warranty_status.title", width:70, valueGetter: params => getMasterLabel("warranty-status",params.data.warrantyStatus) },
        
        { field:"sno",sortable: true, filter:true,  colId:"product.sno", headerName:"R.Sno " ,  resizable: true,},
        { field:"newSrNo",sortable: true, filter:true,  colId:"product.newSrNo", headerName:"D.Sno" ,  resizable: true,},
        
    
        { field:"problem",sortable: true, filter:true,  colId:"problem_list.title", valueGetter: params => getMasterLabel("problem-list",params.data.problem) },
        //{ field:"physicallyCheck",sortable: true, filter:true,  colId:"product.physicallyCheck", valueGetter: params => getMasterLabel("physically-check-list",params.data.physicallyCheck) },
        //{ field:"boxCondition",sortable: true, filter:true,  colId:"product.boxCondition", valueGetter: params => getMasterLabel("box-condition-list",params.data.boxCondition) },
        //{ field:"accessoryPresent",sortable: true, filter:true,  colId:"product.accessoryPresent", valueGetter: params => getMasterLabel("accessory-present-list",params.data.accessoryPresent) },
        { field:"status",sortable: true, filter:true,  colId:"job_status.title", width:80, headerName:"Status", valueGetter: params => getMasterLabel("job-status",params.data.jobStatus) },
        { field:"status",sortable: true, colId:"job.status",  filter:false, cellRenderer: JobStatus, headerName: 'Update Status', width:160, cellRendererParams: { refreshList: getCustomerJobList }, editable: false},
        //{ field:"sno",sortable: true, filter:false,  colId:"product.sno", headerName:"Serial No." , width:300,  resizable: true,cellRenderer:srNoData },
    ]

    const onGridReady =(params)=>{
        grid = {...params}
        getCustomerJobList();
    }
    
    const  exportData = () => {
        grid.api.exportDataAsCsv('jobs');
    }

    return (
        <>
        <div className="row">
                <div className="col-md-12">
                    <span  className="float-start"><h5> Job History </h5>  </span>
                    <span className="float-end mb-2">
                        {customerJobList && masters && <CustomerReceipt customerJobList={customerJobList} getMasterLabel={getMasterLabel} masters={masters} customerId={customerId} />  }
                        {masters && <JobForm refreshList={getCustomerJobList} masters={masters} customerId={customerId} /> }
                    </span>
                </div>
            </div>
        <div className="row">
            <div className="col-md-12">
                <div className="ag-theme-balham" style={{height: 550}}>
                  <AgGridReact
                      rowData={[]}
                      columnDefs={colDefs}
                      defaultColDef={defaultColDef}
                      onGridReady = {(params)=>{ onGridReady(params) }} 
                      onCellValueChanged = {(params)=>{ updateData(params) }}
                      rowClassRules={rowClassRules}
                      
                      >
                  </AgGridReact>
                </div>
            </div>
        </div>
        </>
    )
}
