import { Check } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import React, { useState } from 'react'
import ConfirmDialog from '../../components/ConfirmDialog';
import jobServices from '../jobServices';

export default function JobStatus(props) {


    const [newSrNo, setNewSrNo] = useState("");
    const [savingInProgress, setSavingStatus] = useState(false);

    const sendToerviceCenter = () =>{
        let data = {
            id:props.data.id,
            jobStatus:2

        }
        updateStatus(data);
    }


    const addSrNoAndClose = () =>{
        if(newSrNo !== "") {
            let data = {
                id:props.data.id,
                jobStatus:3,
                newSrNo:newSrNo
            }
            if(newSrNo.length <= 5){
                if( window.confirm("Seems incorrect please confirm!") == true) {
                    updateStatus(data);
                }
            } else {
                updateStatus(data);
            }
        } else {
            alert("Please add new serial number");
        }
        
        
    }

    const handOver = () =>{
        let data = {
            id:props.data.id,
            jobStatus:4
        }
        updateStatus(data);
    }


    

    //console.log(" I am here props",props);
   
    
    const updateStatus = (data) =>{
        
        console.log(data);
        


        
        //4
        
        
        setSavingStatus(true)
        
        jobServices.updateStatus(data).then(res =>{
            setSavingStatus(false);
            console.log(res);
            props.refreshList();

            if(data.jobStatus == 2 || data.jobStatus == 4){
                let customerId = props.data.customerId;
                let jobId = data.id;
    
                //console.log("customerId",customerId)
                //console.log("jobId",jobId)
    
                if(data.jobStatus == 2) {
                    /*
                    jobServices.emailReceipt(customerId,jobId).then(res=>{
                        console.log("receving email Sent");
                        console.log(res);
                    }).catch(error=>{
                        console.log("Error",error);
                    });
                    */
                }
    
                if(data.jobStatus == 4) {
                    /*
                    jobServices.handoverReceipt(customerId,jobId).then(res=>{
                        console.log("handover email Sent");
                        console.log(res);
                    }).catch(error=>{
                        console.log("Error",error);
                    });
                    */
                }
                
                
            }
            
        }).catch(error=>{
            setSavingStatus(false);
        })

        
        

    }


    return (
        <>
            { savingInProgress ? <LinearProgress/> :
                <> 
                    {props.data.jobStatus === 1 && 
                        <>
                            <button type="button" className="btn btn-sm btn-danger" onClick={sendToerviceCenter} >Service</button>
                        </>
                    }
                    {props.data.jobStatus === 2 && 
                        <> 
                            {/* In Servie Enter Serial Number and close */}
                            <input value={newSrNo} onChange={e=>setNewSrNo(e.target.value)} className="form-control p-0 float-start mt-1"  style={{width:"100px", fontSize:12}}  placeholder="New Sr no." type="text"/> 
                            {newSrNo!== "" && <button type="button" onClick={addSrNoAndClose} className="btn btn-sm btn-success p-0 float-end"><Check/> </button>}
                        </>
                    }
                    {props.data.jobStatus === 3 && 
                        <>  {/* Closed now Handover */}
                            <button type="button" className="btn btn-sm btn-warning" onClick={handOver}>Hand Over</button>
                        </>
                    }
                    {props.data.jobStatus === 4 && 
                        <>
                            <span className="p-1 text-white bg-success ">{props.data.newSrNo}</span>
                        </>
                    }
                </>
            }
        </>
    )
    
}

