import { ContactMail, ContactPhone } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router'
import InlineImage from '../../components/InlineEdit/InlineImage';
import InlineTextEdit from '../../components/InlineEdit/InlineTextEdit';
import AppLayout from '../../layout/components/AppLayout'
import ContactComponent from '../../modules/Contact/ContactComponent';
import AddressComponent from '../../modules/Address/AddressComponent';
import MasterServices from '../../services/masterServices';
import CustomerForm from '../components/CustomerForm';
import customerServices from '../customerServices';
import CustomerJobs from '../components/CustomerJobs';
import CustomerJobGrid from '../components/CustomerJobGrid';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

export default function CustomerDetails() {
    let { id } = useParams();
    const authUser = useSelector((state) => state.auth)
    const userId = authUser.id;

    

    const [masters,setMasters] = useState(null);
    const [customerData,setCustomerData] = useState(null);
    const getData = useCallback(()=>{
        getMasterList();
        customerServices.getById(id).then(res=>{
            setCustomerData(res.data);
        }).catch(error=>{

        })
    },[id])


    const getMasterList = () => {
        MasterServices.get(["customer-type"]).then(res=>{
            setMasters(res);
        })
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }

    useEffect(()=>{
        getData();
    },[getData])
    

    /* print Address label  Work  */
    const [dialogStatus, setDialogStatus] = useState(false); 

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    /* Print Work Start */

    const componentRef = useRef(); 
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    /* Print Work End */


    const [workLocationDetails,setWorkLocationDetails] = useState(null);
    const getWorkLocationDetails = (workLocation) => {
        
        if(workLocation!==undefined){
            customerServices.getWorkLocationDetails(workLocation).then(res=>{
                setWorkLocationDetails(res.data);
            })
        }
        

    }

    useEffect(()=>{
        let workLocation = authUser.workLocation;
        getWorkLocationDetails(workLocation);
    },[authUser])

    
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="card mb-2">
                            {customerData && 
                                <div className="card-content">                                
                                    <div className="row p-2">
                                        <div className="col-md-9">
                                            
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        {/* 
                                                            <div className="col-md-12">                                            
                                                                <InlineImage editId={customerData.id}  name="profileImage" value={customerData.profileImage}  handleInlineChange={customerServices.update}  /> 
                                                            </div>
                                                        */}
                                                        <div className="col-md-12">
                                                            <strong>
                                                                { <InlineTextEdit editId={customerData.id}  name="firstName" value={customerData.firstName}  handleInlineChange={customerServices.update}  fontSize={20} /> } 
                                                                { /* <InlineTextEdit editId={customerData.id}  name="lastName" value={customerData.lastName}  handleInlineChange={customerServices.update}  fontSize={20} /> */ }
                                                            </strong>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <ContactPhone/> { <InlineTextEdit editId={customerData.id}  name="phone" value={customerData.phone}  handleInlineChange={customerServices.update}  fontSize={18} /> } 
                                                        </div>
                                                        <div className="col-md-12">
                                                            <ContactMail/> { <InlineTextEdit editId={customerData.id}  name="email" value={customerData.email}  handleInlineChange={customerServices.update}  fontSize={18} /> } 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <p>{customerData.addressLine1}<br/>{customerData.addressLine2}<br/>{customerData.city}, {customerData.state}, {customerData.zip}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-3 mb-2">
                                            {masters && 
                                                <span className="float-end">
                                                    <span className="badge bg-primary ">
                                                        {getMasterLabel("customer-type",customerData.customerType)}
                                                    </span>
                                                    <CustomerForm refreshList={getData} data={customerData} masters={masters}/>
                                                    <br/>
                                                    <button type="button" className="btn btn-sm btn-success" onClick={()=>setDialogStatus(true)}> Print Address Label</button>
                                                </span>
                                            }
                                            
                                            <Dialog
                                                open={dialogStatus}
                                                keepMounted
                                                onClose={handleClose}
                                                fullWidth={true}
                                                maxWidth="md"
                                                aria-describedby="alert-dialog-slide-description"
                                            >
                                                <DialogTitle>{"Print Address"}</DialogTitle>
                                                <DialogContent ref={componentRef}>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h2 className="fw-bold">To </h2>
                                                            <h2 className="ms-5 fw-bold">{customerData.firstName}</h2>
                                                            <h2 className="ms-5 ">{customerData.addressLine1}<br/>{customerData.addressLine2}<br/>{customerData.city}, {customerData.state}, {customerData.zip}</h2>
                                                            <h2 className="ms-5 fw-bold">Phone No : {customerData.phone}</h2>
                                                        </div>
                                                        <hr/>
                                                        {workLocationDetails &&
                                                        <div className="col-md-12">
                                                            <h2 className="fw-bold">From </h2>
                                                            <h2 className="ms-5 fw-bold">Uniworld Vision PVT. LTD</h2>
                                                            <h2 className="ms-5 fw-bold">{authUser.firstName} {authUser.lastName}</h2>
                                                            <h2 className="ms-5 ">{workLocationDetails.address}<br/><br/>{workLocationDetails.city}, {workLocationDetails.state}, {workLocationDetails.zip}</h2>
                                                            <h2 className="ms-5 fw-bold">Phone No : {workLocationDetails.phone}</h2>
                                                        </div>
                                                        }
                                                    </div>

                                                </DialogContent>
                                                <DialogActions><button type="button" className="btn btn-primary" onClick={handlePrint}>Print Label</button></DialogActions>
                                            </Dialog>


                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                {customerData && 
                                    <>
                                        <CustomerJobGrid customerId={customerData.id} customerName={customerData.firstName+" "+customerData.lastName} /> 
                                        {/* <CustomerJobs customerId={customerData.id} customerName={customerData.firstName+" "+customerData.lastName} /> */ }
                                        {/* 

                                            <AddressComponent  userId={userId} module="3" moduleId={customerData.id} titleLine="Customer Address" limit={1}  />
                                            <ContactComponent  userId={userId} module="3" moduleId={customerData.id} titleLine="Other Contact Details" limit={1} />
                                        */}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}
