import React, { useEffect, useState } from 'react'
import AppLayout from '../../layout/components/AppLayout'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Alert, Button, Snackbar } from '@mui/material';
import customerServices from '../customerServices';
import CustomerForm from '../components/CustomerForm';
import MasterServices from '../../services/masterServices';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CellActions = (props) =>{
  //console.log(props)
  let data={
    id:props.data.id,
    firstName:props.data.firstName,
    lastName:props.data.lastName,
    email:props.data.email,
    phone:props.data.phone,
    customerType:props.data.customerType,

    addressLine1:props.data.addressLine1,
    addressLine2:props.data.addressLine2,
    city:props.data.city,
    state:props.data.state,
    zip:props.data.zip,

  };
  return(
    <>
      {/*<CustomerForm refreshList={props.refreshList} data={data} masters={props.masters}/> */}
      <ConfirmDialog id={data.id} confirm={ ()=>props.remove(props.data.id)} >Are you Sure You want to remove</ConfirmDialog>
    </>
  )
}

const CellLink = (props) =>{
  return (
    <>
      <Link to={`/customer/details/${props.data.id}`} style={{color:"#000", fontWeight:"bold"}} >{props.data.id }</Link>
    </>
  )
}

let grid;

export default function CustomerListPage() {

  const [snackStatus, setSnackStatus] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const [masters,setMasters] = useState(null);
  const authUser = useSelector((state) => state.auth)

  

  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:80,
    wrapText: true,     // <-- HERE
    autoHeight: true,   // <-- & HERE 
  })
  

  const getData =  (q="") => {
    console.log(grid);
    customerServices.get(q).then(res=>{
      grid.api.setRowData(res.data);
    })
  }

  const remove = (id)=>{
    let payload = {id:id};
    customerServices.remove(payload).then(res=>{
      getData();
      setSnackStatus(true);
      setSnackType("error");
      setSnackMessage("Removed Successfully");
    }).catch(error=>{
        setSnackStatus(true);
        setSnackMessage(error.msg);
        setSnackType("error");
    })
  }


  const updateDataOnServer = (payload) =>{
    customerServices.save(payload).then(res =>{
      console.log(res);
      if(res.error){
          setSnackStatus(true);
          setSnackType("error");
          setSnackMessage(res.msg);
      } else {
          setSnackStatus(true);
          setSnackType("success");
          setSnackMessage(res.msg);
      }
    }).catch(error=>{
        setSnackStatus(true);
        setSnackType("error");
        setSnackMessage(error.msg);
    })
  }


  const updateData =(params) =>{
    //console.log(params);
    let payload  = {};
    payload[params.column.colId] = params.newValue;
    payload['id'] = params.data.id;
    console.log(payload);
    let result = window.confirm("Are you Sure you want to update")
    if( result) {
      updateDataOnServer(payload);
    }
    
  }


  const colDefs = [
    { width:70, cellRenderer: CellLink, headerName: 'id' },
    { width:120, field:"firstName", headerName: 'Company. Name',filter:true,},
    { width:120, field:"email",sortable: true, filter:true, editable: true, colId:"customer.email"},
    { width:100, field:"phone",sortable: true, filter:true, editable: true, colId:"customer.phone"},
    { width:100, field:"customerType",sortable: true, filter:true,  colId:"customer.customerType",
      valueGetter: function(params) {
        return getMasterLabel("customer-type",params.data.customerType)
      }
    },

    { width:100, field:"addressLine1", headerName:"Address", sortable: true, filter:true, editable: true, colId:"customer.addressLine1",
        valueGetter: function(params) {
        return params.data.addressLine1+" "+params.data.addressLine2;
      }
    },

    { width:100, field:"city",sortable: true, filter:true, editable: true, colId:"customer.city"},
    { width:100, field:"state",sortable: true, filter:true, editable: true, colId:"customer.state"},
    { width:100, field:"zip",sortable: true, filter:true, editable: true, colId:"customer.zip"},

    
  ]

  const adminColDefs = [
    { width:70, cellRenderer: CellLink, headerName: 'id' },
    { width:120, field:"firstName", headerName: 'Company. Name',filter:true,},
    { width:120, field:"email",sortable: true, filter:true, editable: true, colId:"customer.email"},
    { width:100, field:"phone",sortable: true, filter:true, editable: true, colId:"customer.phone"},
    { width:100, field:"customerType",sortable: true, filter:true,  colId:"customer.customerType",
      valueGetter: function(params) {
        return getMasterLabel("customer-type",params.data.customerType)
      }
    },

    { width:100, field:"addressLine1", headerName:"Address", sortable: true, filter:true, editable: true, colId:"customer.addressLine1",
        valueGetter: function(params) {
        return params.data.addressLine1+" "+params.data.addressLine2;
      }
    },

    { width:100, field:"city",sortable: true, filter:true, editable: true, colId:"customer.city"},
    { width:100, field:"state",sortable: true, filter:true, editable: true, colId:"customer.state"},
    { width:100, field:"zip",sortable: true, filter:true, editable: true, colId:"customer.zip"},

    { field:"id", colId:"a.id", cellRenderer: CellActions, headerName: 'Action', cellRendererParams: {
        refreshList:getData,
        remove:remove,
        masters:masters
      }
    },
  ]

  const getMasterList = () => {
    MasterServices.get(["customer-type"]).then(res=>{
        setMasters(res);
    })
  }

  const getMasterLabel = (type, id) => {
      let label = masters[type].find(m => m.value === id)
      return label.label;
  }

  useEffect(() => {
      getMasterList();
      console.log("authuser role",authUser.role);
  }, [])


  const buildQuery = (params) => {
    
    console.log(grid);
    
    let sortOrder = {};
    params.columnApi.getColumnState().forEach(field => {
      if(field.sort!=null){
        sortOrder[field.colId] =  {"sort": field.sort , "sortIndex": field.sortIndex}
      }
    });
    let q = {
        filters:params.api.getFilterModel(),
        sortOrder:sortOrder
    };
    console.log(q);
    let queryString = "?q="+JSON.stringify(q);


    console.log(queryString);
    console.log(queryString.length);
    getData(queryString);
  }


  const onGridReady =(params)=>{
    grid = {...params}
    
    getData();
  }
  
  const  exportData = () => {
    grid.api.exportDataAsCsv('customers');
  }

  console.log(" I am rerendred");

  return (
      <AppLayout>
        <div className="pt-2">
          <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                      <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
          </Snackbar>
          <div className="container-fluid">
              <div className="row">
                
                <div className="col-md-12">
                      <>
                        
                        <div className="row">
                          <div className="col-md-12">
                            <span className="float-end">
                              {masters && <CustomerForm refreshList={getData} masters={masters}/> }
                              <Button onClick={exportData} > Export</Button>
                            </span>
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="col-md-12">
                            
                            <div className="ag-theme-balham" style={{height: 550}}>
                              <AgGridReact
                                  rowData={[]}
                                  columnDefs={ authUser.role === 1 ? adminColDefs : colDefs}
                                  defaultColDef={defaultColDef}

                                  onGridReady = {(params)=>{ onGridReady(params) }} 

                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  onFilterChanged={(params)=>{ buildQuery(params)  }}
                                  onSortChanged={(params)=>{ buildQuery(params) }}
                                  pagination={true}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                        
                      </>
                    
                    
                    {/* JSON.stringify(rowData) */}
                    {/* JSON.stringify(masterColDefs) */}
                </div>
              </div>
          </div>
        </div>
      </AppLayout>
    
  )
}