import { Add, Grid4x4, PeopleAlt, Print, SupportAgent, VoiceChat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AppLayout from '../../layout/components/AppLayout';
import dashboardServices from '../dashboardServices';


export default function Dashboard() {

    const [dashboardData,setDashboardData] = useState(null);
    const getDashboardData = () => {
        dashboardServices.get().then(res=>{
            setDashboardData(res.data);
        })
    }
    useEffect(()=>{
        getDashboardData();
    },[])

    return(
    
      <AppLayout>
        <>


        <div className="container">
            <div className="row mt-3">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <Link to={"/customer/list"} >Customers</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <Link to={"/job/list"} >Jobs</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <Link to={"/master/list"} >Masters</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {dashboardData && 
            <div className="container">
                <div className="row" style={{marginTop:"20px"}}>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark text-white"> Customers</div>
                            <div className="card-body">
                                <div className="row">
                                    <table className="table">
                                        <tbody>
                                            <tr class="table-danger">
                                                <th>New Today</th>
                                                <td>{dashboardData.customerData.newToday}</td>
                                            </tr>
                                            <tr class="table-primary">
                                                <th>New This Month</th>
                                                <td>{dashboardData.customerData.newThisMonth}</td>
                                            </tr>
                                            <tr class="table-success">
                                                <th>Total</th>
                                                <td>{dashboardData.customerData.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-2">
                            <div className="card-header bg-dark text-white"> Jobs</div>
                            <div className="card-body">
                                <div className="row">
                                    <table className="table">
                                        <tbody>
                                            <tr class="table-danger">
                                                <th>New Today</th>
                                                <td>{dashboardData.jobData.newToday}</td>
                                            </tr>
                                            <tr class="table-primary">
                                                <th>New This Month</th>
                                                <td>{dashboardData.jobData.newThisMonth}</td>
                                            </tr>
                                            <tr class="table-success">
                                                <th>Total</th>
                                                <td>{dashboardData.jobData.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-primary text-white"> Job Status</div>
                            <div className="card-body">
                                <div className="row">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th>Open</th>
                                                <td>{dashboardData.jobData.open}</td>
                                            </tr>
                                            <tr>
                                                <th>In Services</th>
                                                <td>{dashboardData.jobData.inService}</td>
                                            </tr>
                                            <tr>
                                                <th>Closed</th>
                                                <td>{dashboardData.jobData.closed}</td>
                                            </tr>
                                            <tr>
                                                <th>Handover</th>
                                                <td>{dashboardData.jobData.handOver}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
        </>
    </AppLayout>

    
 )
}



