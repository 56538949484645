import { CheckBox, CheckBoxOutlineBlankOutlined, Mail, Pending, Print } from '@mui/icons-material'
import { Avatar, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import jobServices from '../../job/jobServices';

export default function CustomerReceipt({customerJobList, getMasterLabel,masters,customerId}) {
    const [dialogStatus, setDialogStatus] = useState(false); 
    const [jobList, setJobList] = useState([]);
    const [printError,setPrintError] = useState("");
    const [emailSentStatus,setEmailSentStatus] = useState("");
    const [emailInprogress,setEmailSendingStatus] = useState(false);

    useEffect(()=>{
        let newJobList = customerJobList.map(job=>{
            job.selected = false;
            return job;
        })
        setJobList(newJobList);
    },[customerJobList])

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const selectJob = (id) =>{
        let newJobList = jobList.map(job=>{
            if(job.id === id){
                job.selected = !job.selected;
            }
            
            return job;
        })
        setJobList(newJobList);
    }

    const selectMultiple = (jobStatus) =>{
        let newJobList = jobList.map(job=>{
            if(job.jobStatus === jobStatus){
                job.selected = !job.selected;
            }
            return job;
        })
        setJobList(newJobList);
    }

    const selectTodaysJob = () => {
        
        var currentDate = moment().format("DD-MM-YYYY");
        //console.log("currentDate",currentDate);
        let newJobList = jobList.map(job=>{
            if(currentDate === moment(job.created_at).format("DD-MM-YYYY")){
                job.selected = true;
            } else {
                job.selected = false;
            }
            return job;
        })
        setJobList(newJobList);
    }

    const selectAllJob = () => {
        let newJobList = jobList.map(job=>{
                job.selected = true;
            return job;
        })
        setJobList(newJobList);
    }


    function printElem(){
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1> Daini' + document.title  + '</h1>');
        mywindow.document.write('</body></html>');
    
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
    
        mywindow.print();
        mywindow.close();
    
        return true;
    }

    function printHtml(html){
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write(html);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(()=>{
            mywindow.print();
            mywindow.close();
        },1000)
        
        
        return true;
    }
    
    const printSelected = () =>{
        let selectedIds = [];
        setPrintError("");
        jobList.forEach(job => {
            if(job.selected){
                selectedIds.push(job.id)
            }
        });
        if(selectedIds.length > 0){
            jobServices.printReceipt(customerId,selectedIds).then(res=>{
                printHtml(res.html);
            }).catch(error=>{
                setPrintError(error.msg)
            })
        } else {
            setPrintError("No job selected");
        }
    }

    const emailSelected = () =>{
        let selectedIds = [];
        setEmailSentStatus("");
        setPrintError("");
        setEmailSendingStatus(true);
        jobList.forEach(job => {
            if(job.selected){
                selectedIds.push(job.id)
            }
        });
        if(selectedIds.length > 0){
            jobServices.emailReceipt(customerId,selectedIds).then(res=>{
                setEmailSentStatus("Message Sent to "+res.customerEmail);
            }).catch(error=>{
                setPrintError(error.msg)
            }).finally(()=>{
                setEmailSendingStatus(false);
            })
        } else {
            setPrintError("No job selected");
        }
    }

    return (
    <>
        <button type="button" className="btn btn-info btn-sm dropdown-toggle me-2" onClick={()=>setDialogStatus(true)}><Print/> Receipt</button>
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Select and Print Job Details"}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-9">
                            <span className="fs"> Select : </span>
                            <button className="btn btn-success btn-sm m-2" onClick={()=>selectTodaysJob()} >All Today's Job</button>
                            <button className="btn btn-warning btn-sm m-2" onClick={()=>selectAllJob()} >All Job</button>
                            {masters && masters['job-status'].map(jobStatus => <button key={jobStatus.value} className="btn btn-dark btn-sm m-2" onClick={()=>selectMultiple(jobStatus.value)} >All { jobStatus.label } Jobs</button>)}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            
                            <List>
                                {jobList &&  jobList.map(job=>
                                    
                                        
                                        <ListItem key={job.id}> 
                                            <ListItemButton onClick={ ()=> selectJob(job.id)} >
                                                <ListItemIcon>
                                                    {job.selected ? <CheckBox className="text-success"/> : <CheckBoxOutlineBlankOutlined/> }
                                                </ListItemIcon>
                                                <ListItemText primary={<>{"Job Id # "+job.id} <span className={"badge "+ jobServices.getJobStatusColor(job.jobStatus)}> {getMasterLabel("job-status",job.jobStatus)} </span>  </>} secondary={<>
                                                        <strong>{getMasterLabel("product-list",job.product)}</strong>, <strong>Brand : </strong>{getMasterLabel("brand-list",job.brand)}, 
                                                        <strong> Model : </strong>{job.model}<br/>
                                                        <strong> Serial No. : </strong>{job.sno}<br/>
                                                        <strong> Job Date : </strong>{ moment(job.created_at).format("llll")}<br/>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    
                                )}
                            </List>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6"><button type="button" className="btn btn-primary mt-5" onClick={printSelected} > <Print/> Print Selected</button></div>
                                <div className="col-md-6">
                                    {emailInprogress ? <CircularProgress/> : <button type="button" className="btn btn-primary mt-5 ms-3" onClick={emailSelected} > <Mail/> Email Selected</button>}
                                </div>
                            </div>
                            
                            
                            {printError !== "" && <h5 className="p-2 text-danger">{printError}</h5>}
                            {emailSentStatus !== "" && <h5 className="p-2 text-success">{emailSentStatus}</h5>}
                            
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
                
        </Dialog>
    </>
  )
}
