import React from 'react'
import AppLayout from '../../layout/components/AppLayout'

export default function BrandListPage() {
  return (
    <AppLayout>
        <div className="container">
            <div>Brand List</div>
        </div>
    </AppLayout>
  )
}
