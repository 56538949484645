import api from "../api/api"

const get = (q) =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getById = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCustomerJobs = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/job/customer/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/customer",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}


const update = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/customer",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}


const remove = (payload) => {
    return new Promise((resolve, reject)=>{
        api.delete("/customer/"+payload.id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })

}

const getWorkLocationDetails = (id) =>{

    console.log("het id is",id)
    return new Promise((resolve, reject)=>{
        api.get("/user-location/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const customerServices = {
    get,
    getById,
    getCustomerJobs,
    save,
    update,
    remove,
    getWorkLocationDetails,
}

export default customerServices;