import React from 'react'
import AppLayout from '../../layout/components/AppLayout'

export default function ModelListPage() {
  return (
    <AppLayout>
        <div className="container">
            <div>Model List</div>
        </div>
    </AppLayout>
  )
}
