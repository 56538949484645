import { AccountCircle, Home, Label, ListAlt, LocalOffer, ManageAccounts, Message, MoreVert, PeopleAlt, Person, Print, Settings, Style, Support, SupportAgent, Tag, VoiceChat, Work } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        //console.log("here in header use effect verified = ",authUser.verified);
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }
    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="/uniworlvision-logo.png" height="50" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                                { authUser.role <= 3 && <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link active" aria-current="page" to="/"> <Home/> Dashboard</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className="nav-link active" aria-current="page" to="/customer/list"> <PeopleAlt/> Customers</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className="nav-link active" aria-current="page" to="/job/list"> <SupportAgent/> Jobs</NavLink>
                                    </li>


                                    { /* 
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="!#" id="supportDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <SupportAgent/> Job </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="supportDropdown">
                                            <li><NavLink className="dropdown-item" to="/support/add"> <Support/> Add Call</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/support/list"> <ListAlt/> List</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/support/print-receipt"> <Print/> Print Receipt</NavLink></li>
                                        </ul>
                                    </li>
                                    */ }

                                    {/*
                                    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="!#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <VoiceChat/> Products </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="productDropdown">
                                            <li><NavLink className="dropdown-item" to="/brand/list"> <Style/> Brands</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/model/list"> <Tag/> Models</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/product/list"> <LocalOffer/> Products</NavLink></li>
                                        </ul>
                                    </li>
                                    */}
                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">

                            { authUser.role == 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    {/*<li><NavLink className="dropdown-item" to="/dealer/list"><Work/> Dealers</NavLink></li> */ }
                                    <li><NavLink className="dropdown-item" to="/master/list"><Label/> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/location"><Settings/> Manage User Locations</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    <li><a className="dropdown-item" href="/auth/profile">My Profile</a></li>
                                    <li><NavLink className="dropdown-item" to="/auth/change-password">Change Password</NavLink></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }

        </>
    )
}
