import moment from 'moment';
import React, { useState,useCallback, useEffect } from 'react'
import jobServices from '../jobServices'

export default function JobActivity({jobId}) {


    const [activityList,setActivityList] = useState(null)


    

    const getActivityList = useCallback(()=>{
        jobServices.getActivity(jobId).then(res=>{
            setActivityList(res.data);
        }).catch(error=>{
            console.log("somthing wend wrong")
        })
    },[jobId]);

    useEffect(()=>{
        getActivityList();
    },[getActivityList])

    return (
        <>
            <div className="card mt-2">
                <div className="card-header bg-primary text-white">Recent Activities</div>
                <div className="card-body">
                    {activityList && 
                        <>
                            {activityList.map(activity  =>
                                <div className="row" key={activity.id} >
                                    <div className="col-md-12">
                                        
                                        <h6 className="small">{ moment(activity.created_at).format("LL")}</h6>
                                        <p className="blockquote-footer m-3"><strong>{activity.createdByFirstName} {activity.createdBylastName} </strong>
                                            {activity.activityId  === 1 &&  <> <strong> {activity.statusTitle} </strong> this job </>}
                                            {activity.activityId  > 1 &&  <> marked as <strong> {activity.statusTitle} </strong> </>}
                                            at  <strong>{ moment(activity.created_at).format("hh:mm A")}</strong>
                                        </p>
                                        
                                    </div>
                                </div>
                            
                            )}
                            
                            <div className="row">
                                { /* JSON.stringify(activityList) */}
                            </div>
                            
                        </>    
                    
                    }
                    

                </div>
            </div>
        </>
        )
    }
