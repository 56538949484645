import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'

import CustomerListPage from '../customers/pages/CustomerListPage'
import ProductListPage from '../products/pages/ProductListPage'
import BrandListPage from '../products/pages/BrandListPage'
import ModelListPage from '../products/pages/ModelListPage'
import SupportListPage from '../cs/pages/SupportListPage'
import AddSupportCallPage from '../cs/pages/AddSupportCallPage'
import PrintReceiptPage from '../cs/pages/PrintReceiptPage'
import DealerListPage from '../dealers/pages/DealerListPage'
import MasterListPage from '../master/pages/MasterListPage'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import Register from '../auth/pages/Register'
import CustomerDetails from '../customers/pages/CustomerDetails'
import JobListPage from '../job/pages/JobListPage'
import JobDetails from '../job/pages/JobDetails'
import UserLocationPage from '../users/pages/UserLocationPage'
import ChangePassword from '../auth/pages/ChangePassword'



export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/change-password" element={ <ChangePassword/> } />
      <Route path="/auth/register" element={ <Register/> } />
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />


      <Route path="/customer/list" element={ <CustomerListPage/> } />
      <Route path="/customer/details/:id" element={ <CustomerDetails /> } />

      <Route path="/job/list" element={ <JobListPage/> } />
      <Route path="/job/details/:id" element={ <JobDetails /> } />

      <Route path="/support/list" element={ <SupportListPage/> } />
      <Route path="/support/add" element={ <AddSupportCallPage/> } />
      <Route path="/support/print-receipt" element={ <PrintReceiptPage/> } />
      


      <Route path="/brand/list" element={ <BrandListPage/> } />
      <Route path="/model/list" element={ <ModelListPage/> } />
      <Route path="/product/list" element={ <ProductListPage/> } />



      <Route path="/dealer/list" element={ <DealerListPage/> } />
      <Route path="/master/list" element={ <MasterListPage/> } />

      <Route path="/message/list" element={ <MasterListPage/> } />


      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />
      <Route path="/user/location" element={ <UserLocationPage /> } />







    </Routes>
  )
}
