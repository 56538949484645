import React from 'react'
import AppLayout from '../../layout/components/AppLayout'

export default function DealerListPage() {
  return (
      <AppLayout>
        <div className="container">
            <div>Dealer List</div>
        </div>
      </AppLayout>
    
  )
}
