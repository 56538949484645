
import React from 'react'

import AppLayout from '../../layout/components/AppLayout'

export default function AddSupportCallPage() {

  

  return (
    <AppLayout>
        <div className="container">
          
            
            
        </div>
    </AppLayout>
  )
}
