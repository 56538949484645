import api from "../api/api"

const get = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/report/dashboard").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const dashboardServices = {
    get,
}

export default dashboardServices;