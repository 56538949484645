import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';
import { useSelector } from 'react-redux'

import { Edit} from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import customerServices from '../customerServices';
import authServices from '../../auth/authServices';
import locationServices from '../../services/locationServices';





export default function CustomerForm({refreshList, masters, data=null}) {

    

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);

    const [savingInProgress, setSavingStatus] = useState(false);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        customerType:"",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        zip:"",
    }


    /* Pin Work*/
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;
    const [pin,setPin] = useState("");
    const verifyPin = (formik) =>{
        authServices.verifyPin({pin:pin,userId:userId}).then(res=>{
            formik.submitForm();
        }).catch(error=>{
            alert("Invalid Pin");
        })
    }
    /* Pin dialog  end*/


    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        onSubmitProps.resetForm();
        customerServices.save(values).then(res =>{
            setSavingStatus(false);
            console.log(res);
            if(res.error){
                setSnackStatus(true);
                setSnackType("error");
                setSnackMessage(res.msg);
                setDialogStatus(false);
            } else {
                refreshList();
                setSnackStatus(true);
                setSnackType("success");
                setSnackMessage(res.msg);
                setDialogStatus(false);
            }
        }).catch(error=>{
            setSavingStatus(false);
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
            setDialogStatus(false);
        })
    }

    const validationRule = Yup.object({
        firstName: Yup.string().required("Required"),
        //lastName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        customerType:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(null);
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            firstName:data.firstName,
            lastName:data.lastName,
            email:data.email,
            phone:data.phone,
            customerType:data.customerType,

            addressLine1:data.addressLine1,
            addressLine2:data.addressLine2,
            city:data.city,
            state:data.state,
            zip:data.zip,
            
        }
        setFormValues(autoFill);

        getCityList(data.state);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }



    const [stateList,setStateList] =useState(null);
    const [cityList,setCityList] =useState();
    const getStateList = () =>{
        locationServices.getStates().then(res=>{
            setStateList(res.data);
        })
    }

    const getCityList = (state) =>{
        locationServices.getCities(state).then(res=>{
            setCityList(res.data);
        })
    }

    useEffect(()=>{
        getStateList();
    },[])


    return (
      <>
          {!data && <button className="btn btn-primary btn-sm" onClick={addNew}> Add New</button>}
          {data && 
            <>
            
            
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            
            </>
         }

        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Manage Customer"} </DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Company Name" name="firstName" 
                                                        className="col-sm-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Customer Name" name="lastName" 
                                                        className="col-sm-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Email" name="email" 
                                                        className="col-sm-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Phone" name="phone" 
                                                        className="col-sm-6 col-xs-12 p-2" 
                                                    />

                                                    {masters && 
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Business Type" name="customerType"  
                                                            options={masters['customer-type']} multiple={false} 
                                                            className="col-md-6 col-xs-6 p-2"
                                                        />
                                                    }
                                                </div>
                                                <div className="row">

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Address Line 1" name="addressLine1" 
                                                        className="col-sm-12 col-xs-12 p-2" 
                                                    />
                                                    <FormikControl control="input" formik={formik} 
                                                        label="Address Line 2" name="addressLine2" 
                                                        className="col-sm-12 col-xs-12 p-2" 
                                                    />
                                                    
                                                    {stateList && 
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="State" name="state"  
                                                            options={stateList} multiple={false} 
                                                            onSelectChange={getCityList}
                                                            className="col-md-6 col-xs-6 p-2"
                                                        />
                                                    }

                                                    {cityList && 
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="City" name="city"  
                                                            options={cityList} multiple={false} 
                                                            className="col-md-6 col-xs-6 p-2"
                                                        />
                                                    }
                                                    
                                                    <FormikControl control="input" formik={formik} 
                                                        label="Pincode" name="zip" 
                                                        className="col-sm-4 col-xs-12 p-2" 
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        {   /*JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                                <button className="btn btn-success" type="submit">Save</button>
                                                <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                            
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>

      </>
    )
}

{/*

<div className="col-md-12">
                                                    <span className="me-3">
                                                        <input placeholder="Enter Your Pin and save" type="password" className="form-cotrol" value={pin} onChange={(e)=>{setPin(e.target.value)}} ></input>
                                                    </span>
                                                    <button className="btn btn-success" type="button" onClick={()=>verifyPin(formik)}>Save</button>
                                                    
                                                    <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                                </div>
*/}