import { ContactMail, ContactPhone } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router'
import InlineImage from '../../components/InlineEdit/InlineImage';
import InlineTextEdit from '../../components/InlineEdit/InlineTextEdit';
import AppLayout from '../../layout/components/AppLayout'
import ContactComponent from '../../modules/Contact/ContactComponent';
import MasterServices from '../../services/masterServices';
import JobForm from '../components/JobForm';
import jobServices from '../jobServices';

import customerServices from '../../customers/customerServices';
import { Link } from 'react-router-dom';
import JobStatusForm from '../components/JobStatusForm';
import JobActivity from '../components/JobActivity';
import moment from 'moment';


const JobData = ({label, value}) => {
    return (
        <div className="row">
            <div className="col-md-6"> <strong>{label}</strong></div>
            <div className="col-md-6">{value}</div>
        </div>
    )
}

const CustomerBasicInfo = ({id}) =>{
    const [customerDetails, setCustomerDetails] = useState(null);

    const getCustomerDetails = () =>{
        customerServices.getById(id).then(res => {
            setCustomerDetails(res.data)
        });
    }

    useEffect(()=>{
        getCustomerDetails();
    },[id])
    return (
        <>
            {customerDetails && 
                <div className="row">
                    <div className="col-md-6"><strong>Customer:</strong></div>
                    <div className="col-md-6"> 
                        <h6>
                            <Link to={"/customer/details/"+customerDetails.id}> {customerDetails.firstName} {customerDetails.lastName}</Link> 
                            <span className="badge bg-primary ms-3">{customerDetails.customerTypeTitle}</span>
                        </h6>
                    </div>
                </div>
            }
        </>
    )
}




export default function JobDetails() {
    let { id } = useParams();
    const authUser = useSelector((state) => state.auth)
    const userId = authUser.id;

    const [masters,setMasters] = useState(null);
    const [jobDetails,setJobDetails] = useState(null);
    const getData = useCallback(()=>{
        getMasterList();
        jobServices.getById(id).then(res=>{
            setJobDetails(res.data);
        }).catch(error=>{

        })
    },[id])
    const getMasterList = () => {
        MasterServices.get(["receving-mode","job-type","warranty-status","brand-list","product-list","problem-list","physically-check-list","box-condition-list","accessory-present-list"]).then(res=>{
            setMasters(res);
        });
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }

    useEffect(()=>{
        getData();
    },[getData])

    return (
        <AppLayout>
            <div className="container">
            {jobDetails &&
                    <>
                        <div className="row mt-3">
                            <div className="col-md-3">
                                <h4 className="text-primary">JOB# : {jobDetails.id}  </h4>
                            </div>
                            <div className="col-md-9">
                                <span className="float-end">
                                    {/* <JobStatusForm jobId={jobDetails.id} refreshList={getData} currentStatus={jobDetails.statusTitle} /> */}
                                    {masters && <JobForm refreshList={getData} masters={masters} data={jobDetails} customerId={jobDetails.customerId} /> }
                                </span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-5">
                                <div className="card">
                                    <div className="card-body">
                                            <h5>Receving Details</h5>
                                            <JobData label="Receving Mode" value={getMasterLabel("receving-mode",jobDetails.recevingMode)}  />
                                            <JobData label="Job Type" value={getMasterLabel("job-type",jobDetails.jobType)}  />
                                            <JobData label="Purchase Bill Date" value={ moment(jobDetails.billDate).format("LL")} />
                                            <JobData label="Warranty Status" value={getMasterLabel("warranty-status",jobDetails.warrantyStatus)}  />
                                        
                                            <hr/>
                                            <h5>Product Details</h5>
                                            <JobData label="Brand" value={getMasterLabel("brand-list",jobDetails.brand)}  />
                                            <JobData label="Product" value={getMasterLabel("product-list",jobDetails.product)}  />
                                            <JobData label="Model" value={jobDetails.model}  />

                                            <hr/>
                                            <h5>Serial No</h5>
                                            <JobData label="Before Service" value={jobDetails.sno}  />
                                            <JobData label="After Service" value={jobDetails.newSrNo}  />
                                            
                                            

                                            <hr/>
                                            <h5>Problem & Conditions</h5>
                                            <JobData label="Problem" value={getMasterLabel("problem-list",jobDetails.problem)}  />
                                            <JobData label="Physically Check" value={getMasterLabel("physically-check-list",jobDetails.physicallyCheck)}  />
                                            <JobData label="Box Condition" value={getMasterLabel("box-condition-list",jobDetails.boxCondition)}  />
                                            <JobData label="Accessory Present" value={getMasterLabel("accessory-present-list",jobDetails.accessoryPresent)}  />

                                            <hr/>
                                            <JobData label="Remark" value={jobDetails.remark}  />

                                    </div>
                                
                                </div>
                            </div> 
                            <div className="col-md-7">
                                <div className="card">
                                    <div className="card-body">
                                        <CustomerBasicInfo id={jobDetails.customerId} />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Job Created By</strong>
                                            </div>
                                            <div className="col-md-6">
                                                {jobDetails.createdBy}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Created on</strong>
                                            </div>
                                            <div className="col-md-6">
                                                {moment(jobDetails.created_at).format("h:mm A on LL")}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <strong>Last Updated</strong>
                                            </div>
                                            <div className="col-md-6">
                                                {moment(jobDetails.updated_at).format("h:mm A on LL")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <JobActivity jobId={id} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </AppLayout>
    )
}
