import React, { useEffect, useState } from 'react'
import AppLayout from '../../layout/components/AppLayout'

import { AgGridReact } from 'ag-grid-react';
import CommonLoadingSkeleton from '../../components/CommonLoadingSkeleton'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import MasterServices from '../../services/masterServices';
import MasterForm from '../components/MasterForm';
import ConfirmDialog from '../../components/ConfirmDialog';
import adminMasterServices from '../adminMasterServices';
import { Alert, Snackbar } from '@mui/material';

const CellActions = (props) =>{
  //console.log(props)
  let data={
    id:props.data.value,
    title:props.data.label
  };
  return(
    <>
      <MasterForm masterName={props.masterName} refreshList={props.refreshList} data={data}/>
      <ConfirmDialog id={data.id} confirm={ ()=>props.remove(props.data.value,props.masterName)} >Are you Sure You want to remove</ConfirmDialog>
    </>
  )
}




export default function MasterListPage() {
  const [rowData,setRowData] = useState(null);
  const [listName,setListName] = useState("accessory-present-list");
  const [loading, setLoadingStatus] = useState(false);
  const [columnDefs,setColumnDefs] = useState(null);

  const [snackStatus, setSnackStatus] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const [defaultColDef] = useState({
    floatingFilter: true,
  })
  

  const getData =  () => {
    setLoadingStatus(true);
    MasterServices.get([listName]).then(res=>{
      setRowData(res[listName]);
      if(listName !== "model-list"){
        setColumnDefs(masterColDefs);
      }
      setLoadingStatus(false);
    })
  }

  const remove = (id,masterName)=>{

    
    let payload = {id,masterName};
    
    adminMasterServices.removeMaster(payload).then(res=>{
      getData();
      setSnackStatus(true);
      setSnackType("error");
      //setSnackMessage(res.data.msg);
      setSnackMessage("Removed Successfully");
    }).catch(error=>{
        setSnackStatus(true);
        setSnackMessage(error.msg);
        setSnackType("error");
    })
  }

  const updateData =(params) =>{
    console.log(params);
  }


  const masterColDefs = [
    { field:"value",sortable: true, filter:true, },
    { field:"label",sortable: true, filter:true, editable: true, colId:"title"},
    { field:"value", colId:"a.id", cellRenderer: CellActions, headerName: 'Action', cellRendererParams: {
      masterName:listName,
      refreshList:getData,
      remove:remove
      
    }
    },
  ]

  useEffect(()=>{
    getData();
  },[listName])
  

  return (
      <AppLayout>
        <div className="pt-2">
          <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                      <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
          </Snackbar>
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="list-group">
                    <a href="#accessory-present-list" onClick={()=>setListName("accessory-present-list")} className={`list-group-item ${listName==="accessory-present-list" ? "active" : ""}`}>Accessory Present List</a>
                    <a href="#box-condition-list" onClick={()=>setListName("box-condition-list")} className={`list-group-item ${listName==="box-condition-list" ? "active" : ""}`}>Box Condition List</a>
                    <a href="#brand-list" onClick={()=>setListName("brand-list")} className={`list-group-item ${listName==="brand-list" ? "active" : ""}`}>Brand List</a>
                    <a href="#business-type" onClick={()=>setListName("business-type")} className={`list-group-item ${listName==="business-type" ? "active" : ""}`}>Business Type</a>
                    <a href="#customer-type" onClick={()=>setListName("customer-type")} className={`list-group-item ${listName==="customer-type" ? "active" : ""}`}>CustomerType</a>
                    <a href="#document-type" onClick={()=>setListName("document-type")} className={`list-group-item ${listName==="document-type" ? "active" : ""}`}>Document Type</a>
                    <a href="#job-type" onClick={()=>setListName("job-type")} className={`list-group-item ${listName==="job-type" ? "active" : ""}`}>Job Type</a>
                    <a href="#job-status" onClick={()=>setListName("job-status")} className={`list-group-item ${listName==="job-status" ? "active" : ""}`}>Job Status</a>
                    <a href="#physically-check-list" onClick={()=>setListName("physically-check-list")} className={`list-group-item ${listName==="physically-check-list" ? "active" : ""}`}>Physically Check List</a>
                    <a href="#problem-list" onClick={()=>setListName("problem-list")} className={`list-group-item ${listName==="problem-list" ? "active" : ""}`}>Problem List</a>
                    <a href="#product-list" onClick={()=>setListName("product-list")} className={`list-group-item ${listName==="product-list" ? "active" : ""}`}>Product List</a>
                    <a href="#receving-mode" onClick={()=>setListName("receving-mode")} className={`list-group-item ${listName==="receving-mode" ? "active" : ""}`}>Receving Mode</a>
                    <a href="#warranty-status" onClick={()=>setListName("warranty-status")} className={`list-group-item ${listName==="warranty-status" ? "active" : ""}`}>Warranty Status</a>
                  </div>
                </div>
                <div className="col-md-9">
                      <>
                        {listName && 
                          <div className="row">
                            <div className="col-md-6">
                              <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                  <li className="breadcrumb-item" aria-current="page">Masters</li>
                                  <li className="breadcrumb-item active" aria-current="page">{listName.charAt(0).toUpperCase() + listName.slice(1).replace("-"," ")}</li> 
                                </ol>
                              </nav>
                            </div>
                            <div className="col-md-6">
                              <MasterForm masterName={listName} refreshList={getData}/>
                            </div>
                          </div>
                        }
                        <div className="row">
                          <div className="col-md-12">
                            {loading &&  <CommonLoadingSkeleton/>}
                            {! loading && rowData==null && <h3>No data Available</h3> }
                            <div className="ag-theme-alpine" style={{height: 400}}>
                              <AgGridReact
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                        
                      </>
                    
                    
                    {/* JSON.stringify(rowData) */}
                    {/* JSON.stringify(masterColDefs) */}
                </div>
              </div>
          </div>
        </div>
      </AppLayout>
    
  )
}