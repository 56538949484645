import api from "../api/api";

const getStates = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/masters/states").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCities = (state)=>{
    return new Promise((resolve, reject)=>{
        api.get("/masters/cities?state="+state).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const locationServices = {
    getStates,
    getCities,
}

export default locationServices;