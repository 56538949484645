import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import { AddCircleOutlineSharp, ContactMail, ContactPhone, Edit} from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import jobServices from '../jobServices';
import MasterServices from '../../services/masterServices';

import { useSelector } from 'react-redux'
import authServices from '../../auth/authServices';


export default function JobForm({refreshList, data=null,customerId=null}) {

    const [masters,setMasters] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [customerDetails, setCustomerDetails] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const initialValues = {
        customerId:customerId?customerId:"",

        recevingMode:"",
        jobType:"",
        billDate:"",
        warrantyStatus:"",

        brand:"",
        product:"",
        model:"",
        sno:"",

        problem:"",
        physicallyCheck:1,
        boxCondition:1,
        accessoryPresent:1,

        remark:"",
        
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        setSavingStatus(true)
        onSubmitProps.resetForm();
        jobServices.save(values).then(res =>{
            setSavingStatus(false);
            console.log(res);
            refreshList();
            setSnackStatus(true);
            setSnackType("success");
            setSnackMessage(res.msg);
            setDialogStatus(false);
        }).catch(error=>{
            setSavingStatus(false);
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
            setDialogStatus(false);
        })
    }

    const validationRule = Yup.object({
        customerId:Yup.string().required("Required"),
        
        recevingMode:Yup.string().required("Required"),
        jobType:Yup.string().required("Required"),
        //billDate:Yup.string().required("Required"),
        warrantyStatus:Yup.string().required("Required"),

        brand:Yup.string().required("Required"),
        product:Yup.string().required("Required"),
        model:Yup.string().required("Required"),
        sno:Yup.string().required("Required"),


        problem:Yup.string().required("Required"),
        physicallyCheck:Yup.string().required("Required"),
        boxCondition:Yup.string().required("Required"),
        accessoryPresent:Yup.string().required("Required"),
        
        

    });

    const addNew = ()=>{
        setFormValues(null);
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            customerId:data.customerId,
            recevingMode:data.recevingMode,
            jobType:data.jobType,
            billDate:data.billDate,
            warrantyStatus:data.warrantyStatus,

            brand:data.brand,
            product:data.product,
            model:data.model,
            sno:data.sno,

            problem:data.problem,
            physicallyCheck:data.physicallyCheck,
            boxCondition:data.boxCondition,
            accessoryPresent:data.accessoryPresent,

            remark:data.remark,
        }
        setFormValues(autoFill);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const getMasterList = () => {        
        MasterServices.get(["receving-mode","job-type","warranty-status","brand-list","product-list","problem-list","physically-check-list","box-condition-list","accessory-present-list"]).then(res=>{
            setMasters(res);
        })
    }

    
    const getCustomerDetails = () => {
        jobServices.getCustomerById(customerId).then(res=>{
            setCustomerDetails(res.data);
        })
    }

    useEffect(()=>{
        getMasterList();
        getCustomerDetails();
    },[])



        /* Pin Work*/
        const authUser = useSelector((state) => state.auth)
        const userId=  authUser.id;
        const userRole=  authUser.role;
        const [pin,setPin] = useState("");
        const verifyPin = (formik) =>{
            authServices.verifyPin({pin:pin,userId:userId}).then(res=>{
                formik.submitForm();
            }).catch(error=>{
                alert("Invalid Pin");
            })
        }
        /* Pin dialog  end*/



    return (
      <>
          {!data && <button className="btn btn-primary btn-sm" onClick={addNew}> <AddCircleOutlineSharp/> Create New Job</button>}
          {data && 
            <>
            
                {userRole === 1 && 
                    <Tooltip title="Update Job">
                        { 
                            <IconButton onClick={updateData} style={{padding:"0px"}}>
                                <Edit/>
                            </IconButton>
                        }
                    </Tooltip>
                }
            
            </>
         }

        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogContent>
                                                            {/* 
                                                            <div className="row p-2">
                                                                <FormikControl control="searchselect" formik={formik}
                                                                    label="Customer" name="customerId"
                                                                    className="col-sm-6 col-xs-6 p-2"
                                                                    inputLabel={['firstName','lastName','phone']}
                                                                    asyncSearch={jobServices.customerSearch}
                                                                />
                                                            </div>
                                                            */}
                                                            {customerDetails && 
                                                                <div className="row pt-2 mt-0 bg-primary text-white border border-secondary rounded">
                                                                    <div className="col-md-6">
                                                                        <h6 className=""><strong>Customer Name : </strong>{customerDetails.firstName} {customerDetails.lastName}</h6>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h6 className=""><strong><ContactPhone/> </strong>{customerDetails.phone} </h6>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <h6 className=""><strong><ContactMail/> </strong>{customerDetails.email} </h6>
                                                                    </div>
                                                                </div>
                                                            }
                                                            
                                                    
                                                            <div className="row p-2">

                                                                <h4>Receving Details</h4>

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Receving Mode" name="recevingMode"  
                                                                        options={masters['receving-mode']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }
                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Job Type" name="jobType"  
                                                                        options={masters['job-type']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                <FormikControl control="date"  formik={formik} type="date" 
                                                                    label="BillDate" name="billDate" 
                                                                    className="col-md-3 col-sm-12 p-2" 
                                                                />

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Warranty Status" name="warrantyStatus"  
                                                                        options={masters['warranty-status']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }
                                                            </div>
                                                        


                                                    
                                                            <div className="row p-2">
                                                                <h4>Product Details</h4>
                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Brand" name="brand"  
                                                                        options={masters['brand-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Product" name="product"  
                                                                        options={masters['product-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }
                                                                
                                                                <FormikControl control="input" formik={formik} 
                                                                    label="Model" name="model" 
                                                                    className="col-sm-3 col-xs-12 p-2" 
                                                                />
                                                                
                                                                <FormikControl control="input" formik={formik} 
                                                                    label="Serial No" name="sno" 
                                                                    className="col-sm-3 col-xs-12 p-2" 
                                                                />
                                                            </div>
                                                    
                                                            <div className="row p-2">
                                                                <h4>Problem And Verification</h4>
                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Problem" name="problem"  
                                                                        options={masters['problem-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Physically Check" name="physicallyCheck"  
                                                                        options={masters['physically-check-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Box Condition" name="boxCondition"  
                                                                        options={masters['box-condition-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Accessory Present" name="accessoryPresent"  
                                                                        options={masters['accessory-present-list']} multiple={false} 
                                                                        className="col-md-3 col-xs-6 p-2"
                                                                    />
                                                                }
                                                                
                                                                <h4>Remark</h4>
                                                                <FormikControl control="input" formik={formik} 
                                                                    label="Remark" name="remark" 
                                                                    className="col-sm-12 col-xs-12 p-2" 
                                                                />
                                                            </div>
                                                    
                                                            <div className="row">
                                                            {  /*  JSON.stringify(formik.values) */ }
                                                            </div>

                                        
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <div className="mt-0">
                                                    {savingInProgress ? 
                                                         <div className="col-md-12"><LinearProgress/></div>
                                                        :
                                                        <div className="col-md-12">
                                                            
                                                            <button className="btn btn-success" type="submit">Save</button>
                                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                                        </div>
                                                        
                                                    }
                                            </div>
                                            
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
      </>
    )
}



{/*
                                                        <div className="col-md-12">
                                                            <span className="me-3">
                                                                <input placeholder="Enter Your Pin and save" type="password" className="form-cotrol" value={pin} onChange={(e)=>{setPin(e.target.value)}} ></input>
                                                            </span>
                                                            <button className="btn btn-success" type="button" onClick={()=>verifyPin(formik)}>Save</button>
                                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                                        </div>
                                                        */}