import React from 'react'
import AppLayout from '../../layout/components/AppLayout'

export default function PrintReceiptPage() {
  return (
    <AppLayout>
        <div className="container">
            <div>PrintReceiptPage</div>
        </div>
    </AppLayout>
  )
}
